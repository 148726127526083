import {
  Card,
  Heading,
  IconButton,
  Text,
  Link,
  useToast,
  HStack,
  Kbd,
  VStack,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import { useTwoFaHelper } from "contexts/TwoFaCheckContext";
import { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { createCredentials, getCredentials } from "services/api.service";
import { getVariable } from "whitelabel";

const CredentialsAPI = () => {
  const toast = useToast();
  const [hidden, setHidden] = useState(true);
  const {
    isOpen: isOpenNewCredentials,
    onOpen: onOpenNewCredentials,
    onClose: onCloseNewCredentials,
  } = useDisclosure();

  const [data, setData] = useState(null);

  const { requestTwoFa } = useTwoFaHelper();

  const handleFindCredentials = async () => {
    const twofa = await requestTwoFa();

    if (!twofa?.id) {
      return false;
    }

    return getCredentials(twofa.id).then((info) => {
      setData(info);
      setHidden(false);
      toast({
        title: "Credenciais encontradas com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return info;
    });
  };
  const checkGenerateCredentials = async () => {
    const credentials = await handleFindCredentials();

    if (credentials === false) {
      return;
    }
    if (!credentials) {
      newCredentials();
    } else {
      onOpenNewCredentials();
    }
  };

  const newCredentials = () => {
    createCredentials().then((data) => {
      setData(data);
      onCloseNewCredentials();
      setHidden(false);
      toast({
        title: "Credenciais geradas com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const copy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      toast({
        title: "Copiado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Card p={2}>
      <Modal onClose={onCloseNewCredentials} isOpen={isOpenNewCredentials}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gerar novas Credenciais</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Deseja realmente gerar novas credenciais? As credenciais atuais
              serão
              <Text as="span" fontWeight="bold">
                {" "}
                permanentemente excluídas
              </Text>
              . Esta ação não pode ser desfeita.
            </Text>
          </ModalBody>
          <ModalFooter>
            <CustomButton colorScheme="brand" onClick={onCloseNewCredentials}>
              Cancelar
            </CustomButton>
            <CustomButton colorScheme="red" onClick={newCredentials}>
              Confirmar
            </CustomButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Heading fontSize={18}>Credenciais da API</Heading>
      <VStack>
        <Text>
          Key:{" "}
          {hidden ? (
            "---"
          ) : (
            <>
              <Kbd>{data?.clientId}</Kbd>
              <IconButton
                size="xs"
                ml={2}
                onClick={() => copy(data?.clientId)}
                rounded="sm"
                variant="outline"
                colorScheme="brand"
                aria-label="Teste"
                icon={<FaCopy />}
              />
            </>
          )}
        </Text>
        <Text>
          Secret:{" "}
          {hidden ? (
            "---"
          ) : (
            <>
              <Kbd>{data?.clientSecret}</Kbd>
              <IconButton
                size="xs"
                ml={2}
                onClick={() => copy(data?.clientSecret)}
                rounded="sm"
                variant="outline"
                colorScheme="brand"
                aria-label="Teste"
                icon={<FaCopy />}
              />
            </>
          )}
        </Text>
        <HStack>
          <CustomButton
            onClick={handleFindCredentials}
            variant="outline"
            colorScheme="brand"
          >
            <Text ml={2}>{hidden ? "Exibir" : "Ocultar"}</Text>
          </CustomButton>

          <CustomButton
            onClick={checkGenerateCredentials}
            variant="outline"
            colorScheme="brand"
          >
            <Text ml={2}>Gerar Credentials</Text>
          </CustomButton>
        </HStack>
      </VStack>

      <Heading mt={5} fontSize={14}>
        Documentação
      </Heading>

      <Text>
        <Link href={getVariable("docs")} target="_blank" color="brand.500">
          Documentação {getVariable("name")}
        </Link>
      </Text>
    </Card>
  );
};

export default CredentialsAPI;
