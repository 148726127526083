import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import { useTwoFaHelper } from "contexts/TwoFaCheckContext";
import useAuth from "contexts/useAuth";
import React from "react";
import { useForm } from "react-hook-form";
import { createTransfer } from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { maskCurrency, onlyNumbers } from "utils/number";

const TransferInternal: React.FC<{}> = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { userData, updateUserData } = useAuth();
  const [loading, setLoading] = React.useState(false);

  const { control, handleSubmit } = useForm<{
    document: string;
    description: string;
    amount: number;
  }>();

  const { requestTwoFa } = useTwoFaHelper();

  const TransferInternal = async (data: any) => {
    const code = await requestTwoFa();
    if (!code?.id) {
      return;
    }
    setLoading(true);
    try {
      await createTransfer(code.id, {
        ...data,
        document: onlyNumbers(data.document),
      });
      updateUserData();
      toast({
        title: "Sucesso",
        description: "Transfêrencia criada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro",
        description:
          error?.response?.data?.message || "Erro ao criar transfêrencia",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomButton colorScheme="brand" variant="outline" onClick={onOpen}>
        Transf. Interna
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> Transfêrencia Interna</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={5} alignItems="start">
                <Text fontWeight="semibold">Dados recebedor</Text>
                <InputForm
                  control={control}
                  label="Documento"
                  name="document"
                  type="tel"
                  rules={{
                    required: true,
                    validate: (value) => {
                      value = onlyNumbers(value);
                      if (validCnpj(value)) return true;
                      if (validCpf(value)) return true;
                      return "Não é válido";
                    },
                  }}
                />

                <InputForm
                  control={control}
                  label="Descrição"
                  name="description"
                  rules={{
                    maxLength: {
                      value: 100,
                      message: "O limite de caracteres é de 100",
                    },
                  }}
                  type="text"
                ></InputForm>

                <InputNumber
                  control={control}
                  percentage={false}
                  placeholder="R$ 0,00"
                  label={"Valor do saque"}
                  name={`amount`}
                  rules={{
                    required: "Campo obrigatório",
                    validate: {
                      positive: (value) =>
                        value > 0 || "O valor deve ser maior que 0",
                      max: (value) =>
                        value <= +userData.balance + +userData.amountLocked ||
                        "O valor deve ser menor que o saldo disponível",
                    },
                  }}
                />
                <Text>Apenas saldo disponível pode ser transferido.</Text>
                <Text>
                  Saldo Disponível: <b>{maskCurrency(userData.balance)}</b>
                </Text>

                <Text>
                  Saldo Bloqueado: <b>{maskCurrency(userData.amountLocked)}</b>{" "}
                </Text>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4}>
                <CustomButton
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(TransferInternal)}
                >
                  Criar
                </CustomButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default TransferInternal;
