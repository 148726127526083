import { Box, Divider, Heading } from "@chakra-ui/react";
import Card from "components/card/Card";
import useAuth from "contexts/useAuth";
import CredentialsAPI from "./components/CredentialsAPI";
import AccountSettings from "./components/AccountSettings";

export default function Settings() {
  const { userData } = useAuth();

  return (
    <Box>
      <Card
        flexDirection="column"
        px={{
          base: 4,
          sm: 2,
        }}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Heading mb={2} fontSize={20}>
          Olá, {userData?.name}
        </Heading>
        <Heading fontSize={20} fontWeight="semibold">
          Configurações
        </Heading>
        <Divider mt={3} />
        <AccountSettings />
        <Divider mt={3} />
        <CredentialsAPI />
      </Card>
    </Box>
  );
}
