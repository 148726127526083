import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import useAuth from "contexts/useAuth";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { changePasswordCustomer } from "services/api.service";
export interface ChangePasswordCustomerForm {
  password: string;
  currentPassword?: string;
}

const ChangePasswordCustomer: React.FC = () => {
  const {
    onOpen: onOpenChangePassword,
    isOpen: isOpenChangePassword,
    onClose: onCloseChangePass,
  } = useDisclosure();

  const toast = useToast();

  const { control, reset, handleSubmit } = useForm<ChangePasswordCustomerForm>({
    defaultValues: {
      password: "",
    },
  });

  const onClose = () => {
    onCloseChangePass();
    reset();
  };
  const { userData } = useAuth();

  const onSubmit = (dataRequest: ChangePasswordCustomerForm) => {
    let request = changePasswordCustomer(
      userData.id,
      dataRequest.password,
      dataRequest.currentPassword
    );

    request
      .then(() => {
        toast({
          title: "Succeso",
          description: `Senha Alterada com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <CustomButton
        onClick={onOpenChangePassword}
        variant="outline"
        colorScheme="brand"
        leftIcon={<FaPen />}
      >
        Alterar Senha
      </CustomButton>
      {isOpenChangePassword && (
        <Portal>
          <Modal isOpen={isOpenChangePassword} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Alterar Senha</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <InputForm
                      label="Senha Atual"
                      placeholder="Digite a senha atual"
                      required
                      type="password"
                      control={control}
                      name="currentPassword"
                    />

                    <InputForm
                      label="Nova Senha"
                      placeholder="Digite a nova senha"
                      required
                      type="password"
                      control={control}
                      name="password"
                      rules={{
                        required: "Campo obrigatório",
                        minLength: {
                          value: 8,
                          message: "A senha deve ter no mínimo 8 caracteres",
                        },
                      }}
                    />
                    <Button
                      type="submit"
                      variant={"outline"}
                      w={`100%`}
                      color={"green"}
                      size={"sm"}
                      rounded={"sm"}
                    >
                      Salvar
                    </Button>
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default ChangePasswordCustomer;
