import React from "react";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import MasterContext from "context";
import ReactGA from "react-ga4";
import Charge from "layouts/charge";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <MasterContext>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Route path={`/charge/:id`} component={Charge} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </HashRouter>
        </MasterContext>
      </React.StrictMode>
    </QueryClientProvider>
  </ChakraProvider>
);
