const whitelabels = {
  divipay: {
    logo: "/divipay.png",
    recurrencyEnabled: false,
    cardsEnabled: true,
    terminalsEnabled: true,
    insuranceEnabled: false,
    logo_inverted: "/divipay-inverted.png",
    favicon: "/divipay.ico",
    banner: "/divipay-banner.png",
    name: "DiviPay",
    support: "mailto:atendimento@divipay.com.br",
    docs: "https://api.divipay.com.br/docs",
    adminUrl: "https://admin.divipay.com.br",
    supportWhatsapp:
      "https://api.whatsapp.com/send?phone=5541997900062&text=Ol%C3%A1%2C%20preciso%20de%20uma%20ajuda.",
    brand: {
      100: "#ffe3b3",
      200: "#ffd181",
      300: "#ffbe4d",
      400: "#ffaf23",
      500: "#ffa100",
      600: "#fc9500",
      700: "#f78500",
      800: "#f17500",
      900: "#d18502",
    },
  },
  hger: {
    recurrencyEnabled: true,
    cardsEnabled: false,
    terminalsEnabled: false,
    insuranceEnabled: false,
    logo: "/hger.png",
    logo_inverted: "/hger-white.png",
    favicon: "/hger.ico",
    banner: "/banner-hger.svg",
    name: "Hger Pay",
    support: "mailto:atendimento@hger.com.br",
    docs: "https://api.hger.com.br/docs",
    adminUrl: "https://admin.hger.com.br",
    supportWhatsapp: "https://api.whatsapp.com/send?phone=49999464580",
    brand: {
      100: "#caf0f8",
      200: "#ade8f4",
      300: "#90e0ef",
      400: "#48cae4",
      500: "#00b4d8",
      600: "#0096c7",
      700: "#0077b6",
      800: "#023e8a",
      900: "#03045e",
    },
  },
};

export const getVariable = (key: string) => {
  return whitelabels[process.env.REACT_APP_WHITELABEL]?.[key];
};
