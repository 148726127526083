import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import FormLabelCustom from "components/FormLabel";
import FormRemoteSelectInput from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import PixKeyInput from "components/PixKeyInput";
import SelectForm from "components/SelectForm";
import useAuth from "contexts/useAuth";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  createWithdrawal,
  findAllParticipants,
  getAccounts,
  validateBillet,
} from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { maskCurrency } from "utils/number";
import { unMaskPixKey } from "utils/pix";
import { onlyNumbers } from "utils/string";
import { z } from "zod";
import { boleto } from "boleto-brasileiro-validator";
import { useTwoFaHelper } from "contexts/TwoFaCheckContext";
import CustomButton from "components/CustomButton";

export const chaveSchema = z.union([
  z.string().regex(/^\d+$/).length(11),
  z.string().regex(/^\d+$/).length(14),
  z.string().regex(/^\+[1-9]\d\d{1,14}$/),
  z
    .string()
    .regex(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    ),
  z
    .string()
    .regex(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
    .length(36),
]);

const CreateWithdrawel: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { userData, updateUserData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState<"DICT" | "MANU" | "BILLET">("DICT");
  const [billetCode, setBilletCode] = React.useState<string | undefined>();
  const [accountId, setAccountId] = React.useState<string | undefined>();

  const { mutate: consultBillet } = useMutation(
    ["consultBillet", billetCode],
    async (billetCode: string) => validateBillet(billetCode)
  );

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<{
    ispb: string;
    keyPix: string;
    amount: number;
    account: string;
    description: string;
    agency: string;
    accountHolderDocument: string;
    accountHolderName: string;
    accountType: string;
    dueDate: string;
  }>();

  const { requestTwoFa } = useTwoFaHelper();

  const createwithdrawel = async (data: any) => {
    const code = await requestTwoFa();
    if (!code?.id) {
      return;
    }

    setLoading(true);
    const pix = unMaskPixKey(data.keyPix);

    try {
      await createWithdrawal(code.id, {
        ...data,
        customerAccountId: accountId,
        accountHolderDocument: data.accountHolderDocument
          ? onlyNumbers(data.accountHolderDocument)
          : undefined,
        ispb: data.ispb?.value,
        type,
        keyPix: pix.raw,
      });
      updateUserData();
      toast({
        title: "Sucesso",
        description: "Saque criado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro",
        description: error?.response?.data?.message || "Erro ao criar saque",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (billetCode?.length >= 44) {
      if (!boleto(billetCode)) {
        toast({
          title: "Erro",
          description: "Boleto inválido",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        consultBillet(billetCode);
      }
    }
  }, [billetCode, consultBillet, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar Saque</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5} alignItems="start">
            <Text fontWeight="semibold">Dados bancários</Text>

            <FormRemoteSelectInput
              control={control}
              label="Conta cadastrada?"
              onChange={(e) => {
                setAccountId(e?.value);
              }}
              loadDataFn={({ value, cb }) =>
                getAccounts({ filter: value }).then((retorno) => {
                  if (retorno?.registers?.length > 0) {
                    cb([
                      { label: "Escolher uma opção...", value: "" },
                      ...retorno.registers?.map((d: any) => ({
                        label:
                          d.accountHolder + " - " + d.pix ||
                          d.agency + " - " + d.account,
                        value: d.id,
                      })),
                    ]);
                  } else {
                    cb([]);
                  }
                })
              }
              name="accountId"
            />
            <FormLabelCustom
              label="Tipo de transfêrencia"
              props={undefined}
              name={""}
            />
            <Select
              onChange={(e) => {
                setType(e.target.value as any);
              }}
              disabled={!!accountId}
            >
              <option value="DICT">Pix com Chave</option>
              <option value="MANU">Pix Manual</option>
              <option value="BILLET">BOLETO</option>
            </Select>

            {type === "BILLET" ? (
              <>
                <InputForm
                  control={control}
                  label="CÓDIGO DO BOLETO"
                  placeholder="CÓDIGO DO BOLETO"
                  name="billetCode"
                  onChange={(value) => {
                    setBilletCode(onlyNumbers(value));
                  }}
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  label="Data de vencimento"
                  placeholder="Data de vencimento"
                  name="dueDate"
                  type="date"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <Text>
                  O prazo de pagamento pode ser alterado e processado no dia
                  seguinte caso o pagamento seja feito após as 17h.
                </Text>
              </>
            ) : (
              <>
                {!accountId && (
                  <>
                    <InputForm
                      control={control}
                      label="Documento recebedor"
                      placeholder="CPF ou CNPJ"
                      name="accountHolderDocument"
                      type="text"
                      rules={{
                        required: "Campo obrigatório",
                        validate: {
                          valid: (value) => {
                            value = onlyNumbers(value);

                            return (
                              validCpf(value) ||
                              validCnpj(value) ||
                              "Documento inválido"
                            );
                          },
                        },
                      }}
                    />
                    <InputForm
                      control={control}
                      placeholder="Nome do recebedor"
                      label="Nome do recebedor"
                      name="accountHolderName"
                      type="text"
                    />
                  </>
                )}

                {type === "DICT" && !accountId && (
                  <>
                    <PixKeyInput
                      label="Chave Pix"
                      control={control}
                      placeholder="Chave pix"
                      name="keyPix"
                      type="text"
                      setValue={setValue}
                    />
                  </>
                )}

                {type === "MANU" && !accountId && (
                  <>
                    <FormRemoteSelectInput
                      control={control}
                      label="Instituição financeira"
                      required
                      isDisabled={!!accountId}
                      registerOptions={{
                        required: !!accountId || "Esse campo é obrigatório",
                      }}
                      loadDataFn={({ value, cb }) =>
                        findAllParticipants({ filter: value }).then(
                          (retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb(
                                retorno.registers?.map((d: any) => ({
                                  label: d.reducedName,
                                  value: d.ispb,
                                }))
                              );
                            } else {
                              cb([]);
                            }
                          }
                        )
                      }
                      name="ispb"
                    />
                    <SelectForm
                      control={control}
                      label="Tipo de conta"
                      name="accountType"
                      options={[
                        {
                          label: "Conta Corrente",
                          value: "CACC",
                        },
                        {
                          label: "Conta de Pagamento",
                          value: "TRAN",
                        },
                        {
                          label: "Conta Poupança",
                          value: "SVGS",
                        },
                      ]}
                    />
                    <FormControl isInvalid={!!errors.agency?.message}>
                      <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                        Agência<Text>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        maxLength={4}
                        isDisabled={!!accountId}
                        name="agency"
                        {...register("agency", {
                          required: !!accountId,
                          minLength: {
                            message:
                              "Esse campo deve ter no mínimo 4 caracteres",
                            value: 4,
                          },
                          maxLength: {
                            message:
                              "Esse campo deve ter no máximo 4 caracteres",
                            value: 4,
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="number"
                        placeholder="0001"
                        required
                      />
                      <FormErrorMessage>
                        {errors.agency?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.account?.message}>
                      <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                        Conta (com dígito)<Text>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        isDisabled={!!accountId}
                        fontSize="sm"
                        name="account"
                        {...register("account", {
                          required: !!accountId || "Esse Campo é Obrigatório",
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="tel"
                        placeholder="00101"
                      />
                      <FormErrorMessage>
                        {errors.account?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </>
            )}

            <InputNumber
              control={control}
              percentage={false}
              placeholder="R$ 0,00"
              label={"Valor"}
              name={`amount`}
              rules={{
                required: "Campo obrigatório",
                validate: {
                  positive: (value) =>
                    value > 0 || "O valor deve ser maior que 0",
                  max: (value) =>
                    value <= +userData.balance - +userData.amountLocked ||
                    "O valor deve ser menor que o saldo disponível",
                },
              }}
            />
            <Text>
              Saldo Disponível:{" "}
              <b>{maskCurrency(userData.balance - +userData.amountLocked)}</b>
            </Text>
            <FormControl isInvalid={!!errors.description?.message}>
              <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                Descrição<Text>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                size="sm"
                {...register("description", {
                  maxLength: {
                    value: 100,
                    message: "O limite de caracteres é de 100",
                  },
                })}
                ms={{ base: "0px", md: "0px" }}
                type="text"
                rounded="sm"
                placeholder="Pagamento de contas"
              />
              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={4}>
            <CustomButton
              colorScheme="green"
              isLoading={loading}
              onClick={handleSubmit(createwithdrawel)}
            >
              Criar
            </CustomButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateWithdrawel;
