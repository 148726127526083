import {
  Button,
  Highlight,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import { queryClient } from "index";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";
import { useForm } from "react-hook-form";
import { createCharge } from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { onlyNumbers } from "utils/number";

const CreateCharge: React.FC<{}> = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showPayer, setShowPayer] = React.useState(false);
  const [showIten, setShowIten] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [infoCobranca, setInfoCobranca] = React.useState<any>({});

  const { control, handleSubmit, reset, setValue } = useForm<{
    document: string;
    description: string;
    amount: number;
    name: string;
    dueDate: string;
    itens: {
      description: string;
      amount: number;
    }[];
  }>();

  const createNewCharge = async (data: any) => {
    setLoading(true);
    try {
      const charge = await createCharge({
        ...data,
        document: onlyNumbers(data?.document),
      });
      setInfoCobranca(charge);
      queryClient.refetchQueries({
        queryKey: ["findAllCharges"],
        type: "active",
      });
      toast({
        title: "Sucesso",
        description: "Cobrança criada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      console.log(error);

      toast({
        title: "Erro",
        description:
          error?.response?.data?.message || "Erro ao criar transfêrencia",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomButton
        colorScheme="brand"
        variant="solid"
        onClick={() => {
          onOpen();
          reset();
          setInfoCobranca(null);
        }}
      >
        Criar cobrança
      </CustomButton>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setInfoCobranca(null);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar cobrança</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={5} alignItems="center">
                <Text fontWeight="semibold">Dados da cobrança</Text>

                {infoCobranca?.id ? (
                  <>
                    <Text>
                      Cobrança criada com sucesso, compartilhe o link abaixo:
                    </Text>
                    <Text
                      onClick={() => {
                        navigator.clipboard.writeText(infoCobranca?.qrCode);
                        toast({
                          title: "Sucesso",
                          description: "Link copiado com sucesso",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                      _hover={{
                        cursor: "pointer",
                      }}
                      w={"100%"}
                    >
                      <Highlight
                        query={infoCobranca?.qrCode}
                        styles={{
                          px: "1",
                          py: "1",
                          width: "100%",
                          bg: "orange.100",
                          whiteSpace: "wrap",
                        }}
                      >
                        {infoCobranca?.qrCode}
                      </Highlight>
                    </Text>

                    <Text fontWeight={700}>QR Code:</Text>
                    <QRCodeCanvas
                      size={320}
                      height={"320px"}
                      width={"320px"}
                      value={infoCobranca.qrCode}
                    />
                  </>
                ) : (
                  <>
                    <InputNumber
                      control={control}
                      placeholder="R$ 0,00"
                      label={"Valor da cobrança"}
                      name={`amount`}
                      rules={{
                        required: "Campo obrigatório",
                        validate: {
                          positive: (value) =>
                            value > 0 || "O valor deve ser maior que 0",
                        },
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Descrição adicional"
                      name="description"
                      placeholder="Pagamento referencia a..."
                      type="text"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    ></InputForm>

                    <InputForm
                      control={control}
                      label="Data de vencimento"
                      name="dueDate"
                      type="date"
                    ></InputForm>

                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        if (showPayer) {
                          setValue("name", "");
                          setValue("document", "");
                        }
                        setShowPayer(!showPayer);
                      }}
                    >
                      Informar dados do pagador?
                    </Button>
                    {
                      // @ts-ignore
                      showPayer && (
                        <>
                          <Text fontWeight="semibold">Dados do pagador</Text>
                          <InputForm
                            control={control}
                            label="Nome"
                            name="name"
                            type="text"
                            placeholder="Nome completo"
                          ></InputForm>

                          <InputForm
                            control={control}
                            label="CPF/CNPJ"
                            placeholder="000.000.000-00"
                            name="document"
                            type="tel"
                            rules={{
                              required: true,
                              validate: (value) => {
                                value = onlyNumbers(value);
                                if (validCnpj(value)) return true;
                                if (validCpf(value)) return true;
                                return "Documento não é válido";
                              },
                            }}
                          ></InputForm>
                        </>
                      )
                    }

                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        if (showIten) {
                          setValue("itens", []);
                        }
                        setShowIten(!showIten);
                      }}
                    >
                      Adicionar item a cobrança?
                    </Button>

                    {
                      // @ts-ignore
                      showIten && (
                        <>
                          <Text fontWeight="semibold">Itens</Text>

                          <InputForm
                            control={control}
                            label="Descrição"
                            name="itens[0].description"
                            type="text"
                            placeholder="Descrição do item"
                            rules={{
                              required: "Campo obrigatório",
                            }}
                          ></InputForm>

                          <InputNumber
                            control={control}
                            placeholder="R$ 0,00"
                            label={"Valor do item"}
                            name={`itens[0].amount`}
                            rules={{
                              required: "Campo obrigatório",
                              validate: {
                                positive: (value) =>
                                  value > 0 || "O valor deve ser maior que 0",
                              },
                            }}
                          />
                        </>
                      )
                    }
                  </>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              {!infoCobranca && (
                <CustomButton
                  colorScheme="green"
                  variant="solid"
                  isLoading={loading}
                  onClick={handleSubmit(createNewCharge)}
                >
                  Criar cobrança
                </CustomButton>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateCharge;
