// Chakra Imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import useAuth from "contexts/useAuth";
import PropTypes from "prop-types";
// Assets
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import routes from "routes";
import { maskCurrency } from "utils/number";
export default function HeaderLinks(props: { secondary: boolean }) {
  const { secondary } = props;
  const history = useHistory();

  const { userData, signOut } = useAuth();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />

      <VStack spacing={0} mr={2} alignItems="end">
        <Tooltip
          label={
            <>
              <Text>Saldo Disponível:</Text>
              {maskCurrency(userData.balance)}
              <Text>Saldo em liberação:</Text>
              {maskCurrency(userData.balancePending)}
              <Text>Saldo bloqueado:</Text>
              {maskCurrency(userData.amountLocked)}
            </>
          }
        >
          <Text
            cursor={`pointer`}
            whiteSpace={`nowrap`}
            display={{
              base: "none",
              md: "flex",
            }}
            color="gray.500"
            height={`20px`}
            fontSize={"sm"}
          >
            {maskCurrency(userData.balance)}
          </Text>
        </Tooltip>
      </VStack>
      <Box display={{ sm: "none", xl: "flex" }}>
        <Menu>
          <MenuButton p="0px">
            <Icon
              mt="6px"
              as={MdNotificationsNone}
              color={navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notificações
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
              >
                Marcar como lido
              </Text>
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton p="0px">
            <Icon
              mt="6px"
              as={MdInfoOutline}
              color={navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            me={{ base: "30px", md: "unset" }}
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            minW={{ base: "unset" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex flexDirection="column">
              <Link w="100%" href="tel:11999999999">
                <Button
                  w="100%"
                  h="44px"
                  mb="10px"
                  border="1px solid"
                  bg="transparent"
                  borderColor={borderButton}
                >
                  Contato: (11) 9 9999-9999
                </Button>
              </Link>
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name="Usuário"
              bg="brand.900"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Olá {userData?.name}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Configurações</Text>
              </MenuItem>

              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={() => signOut(history)}
              >
                <Text fontSize="sm">Sair</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
