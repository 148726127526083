// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { maskCurrency } from "utils/number";

export default function Default(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name: string;
  growth?: number;
  value: string | number;
  sumValue?: string | number;
}) {
  const { startContent, endContent, name, growth, value, sumValue } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px" border="1px solid #E6ECFA" borderRadius="20px">
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
      >
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: "sm",
            }}
          >
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "2xl",
            }}
          >
            {value}
          </StatNumber>
          {growth ? (
            <Flex align="center">
              <Text
                color={growth > 0 ? "green.500" : "red.500"}
                fontSize="xs"
                fontWeight="700"
                me="5px"
              >
                {growth > 0 ? "+" : ""}
                {growth.toFixed(0)}%
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                desde o mês passado
              </Text>
            </Flex>
          ) : null}
          {sumValue ? (
            <Flex align="center">
              <Text color={"green.500"} fontSize="xs" fontWeight="700" me="5px">
                {maskCurrency(+sumValue / +value)}
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                de Ticket Médio
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
