import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Progress,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import QRCode from "qrcode.react";
import React, { useCallback, useEffect } from "react";
import { BsQrCode } from "react-icons/bs";
import { generateInstantCharge, getCharge } from "services/api.service";
import { InstantQrCode } from "types/Charge";
import { maskCurrency } from "utils/number";
import { ChargeStatus } from "../charges";
import { FaCheckCircle } from "react-icons/fa";
import { FiXCircle } from "react-icons/fi";
import Countdown from "react-countdown";
import CustomButton from "components/CustomButton";

const expirationSecondsCharge = 120;

const Digit: React.FC<{ children: string; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      width={"40px"}
      height={"40px"}
      padding={9}
      rounded="full"
      variant="outline"
      colorScheme="brand"
      fontWeight={"bold"}
      fontSize={"2xl"}
    >
      {children}
    </Button>
  );
};

const KeyboardNumber: React.FC<{
  onClick: (value: number) => Promise<void>;
  buttonName: string;
}> = ({ onClick, buttonName }) => {
  const [value, setValue] = React.useState("0");
  const [isLoading, setIsLoading] = React.useState(false);

  const toast = useToast();

  const click = (value: string) => {
    setValue((prev) => {
      if (prev === "0") {
        return value;
      }
      if (prev.length > 8) {
        return prev;
      }
      return prev + value;
    });
  };

  const del = () => {
    setValue((prev) => prev.slice(0, -1));
  };

  const handleClick = () => {
    const amount = parseFloat(value) / 100;

    if (isNaN(amount)) {
      toast({
        title: "Valor inválido",
        description: "Digite um valor válido",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    onClick(amount).finally(() => {
      setIsLoading(false);
    });
  };

  const keyPressFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Backspace") {
      del();
    }

    if (event.keyCode >= 48 && event.keyCode <= 57) {
      click(event.key);
    }

    if (event.keyCode >= 96 && event.keyCode <= 105) {
      click(event.key);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPressFunction);

    return () => {
      document.removeEventListener("keydown", keyPressFunction);
    };
  }, [keyPressFunction]);

  return (
    <>
      <Text fontSize="4xl" textAlign="center" mb={4} fontWeight="bold">
        {maskCurrency(parseFloat(value) / 100)}
      </Text>
      <VStack spacing={4}>
        <HStack>
          <Digit onClick={() => click("1")}>1</Digit>
          <Digit onClick={() => click("2")}>2</Digit>
          <Digit onClick={() => click("3")}>3</Digit>
        </HStack>
        <HStack>
          <Digit onClick={() => click("4")}>4</Digit>
          <Digit onClick={() => click("5")}>5</Digit>
          <Digit onClick={() => click("6")}>6</Digit>
        </HStack>
        <HStack>
          <Digit onClick={() => click("7")}>7</Digit>
          <Digit onClick={() => click("8")}>8</Digit>
          <Digit onClick={() => click("9")}>9</Digit>
        </HStack>
        <HStack>
          <Digit onClick={() => click("0")}>0</Digit>
          <Digit onClick={() => click("00")}>00</Digit>
          <Digit onClick={() => del()}>{"<"}</Digit>
        </HStack>
      </VStack>
      <Button
        mt={10}
        mb={5}
        width={"100%"}
        colorScheme="brand"
        isLoading={isLoading}
        onClick={handleClick}
      >
        {buttonName}
      </Button>
    </>
  );
};

const ChargeData: React.FC<{
  chargeData: InstantQrCode;
  setChargeData: React.Dispatch<
    React.SetStateAction<InstantQrCode | undefined>
  >;
}> = ({ chargeData, setChargeData }) => {
  const { data } = useQuery(
    ["pix", chargeData?.id],
    () => getCharge(chargeData?.id),
    {
      enabled: chargeData?.id !== undefined,
      refetchInterval: (data) => {
        if (data?.status !== "PENDING") {
          return false;
        }
        return 1000;
      },
    }
  );

  if (chargeData && (!data?.status || data?.status === ChargeStatus.PENDING)) {
    return (
      <>
        <VStack mb={10}>
          <Text fontSize="3xl" mb={5}>
            Valor: <b> {maskCurrency(chargeData.amount)}</b>
          </Text>
          <QRCode value={chargeData.qrCode} size={240} />

          <Countdown
            date={DateTime.fromISO(chargeData.expirationDate).toJSDate()}
            renderer={({ total }) => {
              total = total / 1000;
              return (
                <>
                  <Progress
                    value={(total / expirationSecondsCharge) * 100}
                    width="100%"
                    mt={2}
                  />
                  <Text fontSize="xl" fontWeight="bold">
                    {total} segundos
                  </Text>
                </>
              );
            }}
          />
        </VStack>
        <CustomButton
          variant="ghost"
          onClick={() => {
            setChargeData(undefined);
          }}
        >
          <>{"<"} Nova cobrança</>
        </CustomButton>
      </>
    );
  }

  if (chargeData && data?.status && data?.status !== ChargeStatus.PENDING) {
    return (
      <Text fontSize="xl" fontWeight="bold">
        {data?.status === ChargeStatus.PAID ? (
          <VStack>
            <Text>Pagamento recebido</Text>
            <Icon color="green.500" boxSize={20} as={FaCheckCircle} />

            <Text>Valor: {maskCurrency(chargeData?.amount)}</Text>
          </VStack>
        ) : (
          <VStack>
            <Text>Tempo expirado</Text>
            <Icon color="red.500" boxSize={20} as={FiXCircle} />
            <Text>Valor: {maskCurrency(chargeData?.amount)}</Text>
          </VStack>
        )}
        <CustomButton
          variant="ghost"
          onClick={() => {
            setChargeData(undefined);
          }}
        >
          <>{"<"} Nova cobrança</>
        </CustomButton>
      </Text>
    );
  }
};

const CreateCharge = () => {
  const {
    isOpen: isCreateChargeOpen,
    onOpen: onCreateChargeOpen,
    onClose: onCreateChargeClose,
  } = useDisclosure();

  const [chargeData, setChargeData] = React.useState<InstantQrCode>();
  const toast = useToast();

  const handleGenerarPix = async (amount: number) => {
    await generateInstantCharge({ amount, expiration: expirationSecondsCharge })
      .then((response) => {
        setChargeData(response);
      })
      .catch((error) => {
        toast({
          title: "Erro ao gerar QR Code",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (!isCreateChargeOpen) {
      setChargeData(undefined);
    }
  }, [isCreateChargeOpen]);

  return (
    <>
      <Button
        variant="solid"
        rounded="sm"
        width="100%"
        display={{
          md: "none",
          sm: "flex",
        }}
        height={"50px"}
        leftIcon={<Icon as={BsQrCode} />}
        colorScheme="brand"
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => {
          onCreateChargeOpen();
        }}
        mb={4}
        borderRadius="md"
      >
        Cobrar por Pix
      </Button>
      {isCreateChargeOpen && (
        <Drawer
          isOpen={isCreateChargeOpen}
          placement="bottom"
          onClose={onCreateChargeClose}
        >
          <DrawerOverlay />
          <DrawerContent pb={3}>
            <DrawerCloseButton />
            <DrawerHeader>Receber Pix</DrawerHeader>

            <DrawerBody>
              {isCreateChargeOpen && !chargeData && (
                <KeyboardNumber
                  onClick={handleGenerarPix}
                  buttonName="Gerar QR Code"
                />
              )}
              {chargeData && (
                <ChargeData
                  chargeData={chargeData}
                  setChargeData={setChargeData}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default CreateCharge;
