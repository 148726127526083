import { TwoFaHelperProvider } from "contexts/TwoFaCheckContext";
import { AuthProvider } from "contexts/useAuth";
import { useEffect } from "react";
import { getVariable } from "whitelabel";
import { SplashScreen } from "@capacitor/splash-screen";
import { Capacitor } from "@capacitor/core";
import OneSignal from "onesignal-cordova-plugin";

export default function MasterContext({ children }: any) {
  useEffect(() => {
    document.title = getVariable("name") + " - Acesso";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link["rel"] = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link["href"] = getVariable("favicon");
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) SplashScreen.hide();
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      OneSignal.initialize(process.env.REACT_APP_ONE_SIGNAL_APP_ID);
      OneSignal.Notifications.addEventListener("click", async (e) => {
        let clickData = await e.notification;
        console.log("Notification Clicked : " + clickData);
      });

      OneSignal.Notifications.requestPermission(true).then(
        (success: Boolean) => {
          console.log("Notification permission granted " + success);
        }
      );
    }
  }, []);

  return (
    <AuthProvider>
      <TwoFaHelperProvider>{children}</TwoFaHelperProvider>
    </AuthProvider>
  );
}
