import {
  MdAccountBalanceWallet,
  MdBarChart,
  MdHome,
  MdLock,
  MdPin,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Orders from "views/admin/orders";

// Auth Imports
import { IoCashOutline } from "react-icons/io5";
import SignIn from "views/auth/signIn";
import Movement from "views/admin/orders/Movement";
import Withdrawals from "views/admin/withdrawals";
import Terminals from "views/admin/maquinetas";
import Balances from "views/admin/balances/balances";
import Privacy from "views/auth/signIn/privacy";
import Settings from "views/admin/customers/settings";
import Charges from "views/admin/charges";
import { FaFileInvoiceDollar, FaIdCard, FaReceipt } from "react-icons/fa";
import { BiHourglass } from "react-icons/bi";
import Charge from "views/admin/charges/Charge";
import BalancesPending from "views/admin/balances/balances-pending";
import { BsSafe2 } from "react-icons/bs";
import Insurances from "views/admin/insurances";
import Clients from "views/admin/clients";
import RecurrencesPlans from "views/admin/recurrences/plans";
import ShowRecurrence from "views/admin/recurrences/ShowRecurrence";
import { getVariable } from "whitelabel";
import CardsPage from "views/admin/card/CardsPage";
import Recurrency from "views/admin/recurrences/list-recurrences";
import { FiSettings } from "react-icons/fi";
import { LuBoxes } from "react-icons/lu";
import { PiRepeat, PiUsersFour } from "react-icons/pi";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    pathPrevious: "/#/admin/dashboard",
    namePrevious: "Início",
    path: "/dashboard",
    icon: MdHome,
    component: MainDashboard,
  },
  {
    name: "Vendas",
    layout: "/admin",
    icon: MdBarChart,
    path: "/transactions",
    component: Orders,
  },
  {
    name: "Cobrança",
    layout: "/admin",
    icon: MdBarChart,
    path: "/charge/:id",
    hideOnSideBar: true,
    component: Charge,
  },
  ...(getVariable("recurrencyEnabled")
    ? [
        {
          name: "Recorrências",
          layout: "/admin",
          path: "",
          icon: PiRepeat,
          children: [
            {
              name: "Clientes",
              layout: "/admin",
              icon: PiUsersFour,
              path: "/clients",
              component: Clients,
            },

            {
              name: "Recorrências ",
              layout: "/admin",
              icon: FaReceipt,
              path: "/recurrences",
              component: Recurrency,
            },
            {
              name: "Planos de Recorrências",
              layout: "/admin",
              icon: LuBoxes,
              path: "/recurrence-plans",
              component: RecurrencesPlans,
            },
            {
              name: "Consultar Recorrência",
              layout: "/admin",
              hideOnSideBar: true,
              icon: FaReceipt,
              path: "/recurrence/:id",
              component: ShowRecurrence,
            },
          ],
        },
      ]
    : []),
  {
    name: "Cobranças",
    layout: "/admin",
    icon: FaFileInvoiceDollar,
    path: "/charges",
    component: Charges,
  },
  {
    name: "Saldos",
    layout: "/admin",
    icon: MdAccountBalanceWallet,
    children: [
      {
        name: "Liberados",
        layout: "/admin",
        icon: MdAccountBalanceWallet,
        path: "/balances",
        component: Balances,
      },

      {
        name: "Pendentes",
        layout: "/admin",
        icon: BiHourglass,
        path: "/balance-pending",
        component: BalancesPending,
      },
    ],
  },

  {
    name: "Movimento",
    layout: "/admin",
    icon: MdBarChart,
    path: "/transactions/:id",
    hideOnSideBar: true,
    component: Orders,
  },
  ...(getVariable("insuranceEnabled")
    ? [
        {
          name: "Seguros",
          layout: "/admin",
          isAdmin: true,
          icon: BsSafe2,
          path: "/insurances",
          component: Insurances,
        },
      ]
    : []),

  {
    name: "Saques",
    layout: "/admin",
    icon: IoCashOutline,
    path: "/saques",
    component: Withdrawals,
  },
  {
    name: "Venda",
    layout: "/admin",
    icon: MdBarChart,
    path: "/movement/:id",
    pathPrevious: "/#/admin/transactions",
    namePrevious: "Vendas",
    component: Movement,
    hideOnSideBar: true,
  },
  ...(getVariable("terminalsEnabled")
    ? [
        {
          name: "Terminais",
          layout: "/admin",
          icon: MdPin,
          path: "/terminals",
          component: Terminals,
        },
      ]
    : []),
  ...(getVariable("cardsEnabled")
    ? [
        {
          name: "Cartões",
          layout: "/admin",
          icon: FaIdCard,
          path: "/cards",
          component: CardsPage,
        },
      ]
    : []),

  {
    name: "Configurações",
    layout: "/admin",
    icon: FiSettings,
    path: "/settings",
    pathPrevious: "/#/dashboard",
    namePrevious: "Início",
    component: Settings,
  },
  {
    name: "Entrar",
    layout: "/auth",
    hideOnSideBar: true,
    path: "/sign-in",
    icon: MdLock,
    component: SignIn,
  },
  {
    name: "Política de Privacidade",
    layout: "/auth",
    hideOnSideBar: true,
    path: "/privacy",
    icon: MdLock,
    component: Privacy,
  },
];

export default routes;
