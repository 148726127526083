import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import ChargeStatistics from "components/card/ChargeStatistics";
//import PieChart from "components/charts/PieChart";
import IconBox from "components/icons/IconBox";
import useAuth from "contexts/useAuth";
import {
  MdTaskAlt,
  MdAttachMoney,
  MdOutlineAutorenew,
  MdBlock,
  MdBarChart,
  MdCancel,
  MdSell,
  MdCheck,
  MdPendingActions,
  MdPix,
} from "react-icons/md";

import { dashboardCustomer, findAllTerminals } from "services/api.service";
import { maskCurrency } from "utils/number";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import Card from "components/card/Card";
import { BsCash, BsCreditCard, BsCreditCardFill } from "react-icons/bs";

import { useState } from "react";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import SelectForm from "components/SelectForm";
import { FaBarcode } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import CreateCharge from "./CreateCharge";
import CustomButton from "components/CustomButton";

interface DashboardForm {
  type?: string;
  initialDate: string;
  finalDate: string;
  company: SelectOptionType;
  customer: SelectOptionType;
}

export default function UserReports() {
  // Chakra Color Mode
  const toast = useToast();

  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };
  const { userData } = useAuth();
  const [filter, setFilter] = useState<{
    type?: string;
    initialDate: string;
    finalDate: string;
    companyId?: string;
    customerId?: string;
  }>({
    finalDate: DateTime.local().endOf("month").toFormat("yyyy-MM-dd'T'hh:mm"),
    initialDate: DateTime.local()
      .startOf("month")
      .toFormat("yyyy-MM-dd'T'HH:mm"),
  });

  const { control, handleSubmit } = useForm<DashboardForm>({
    defaultValues: {
      finalDate: DateTime.local().endOf("month").toFormat("yyyy-MM-dd'T'hh:mm"),
      initialDate: DateTime.local()
        .startOf("month")
        .toFormat("yyyy-MM-dd'T'HH:mm"),
    },
  });

  const { data, refetch } = useQuery(
    ["dashboard", filter],
    () => dashboardCustomer(filter),
    {}
  );

  const buscar = (data: DashboardForm) => {
    if (DateTime.fromISO(data.initialDate) > DateTime.fromISO(data.finalDate)) {
      toast({
        title: "Erro",
        description: "A data inicial não pode ser maior que a data final",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (
      DateTime.fromISO(data.initialDate)
        .diff(DateTime.fromISO(data.finalDate))
        .as("days") < -180
    ) {
      toast({
        title: "Erro",
        description:
          "A diferença entre as datas não pode ser maior que 180 dias",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    const search = {
      type: data.type,
      initialDate: data.initialDate,
      finalDate: data.finalDate,
      companyId: data.company?.value || undefined,
      customerId: data.customer?.value || undefined,
    };
    if (search !== filter) {
      refetch();
    }
    setFilter(search);
  };

  const brandColor = useColorModeValue("brand.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const percenteProfit = data?.pastMonthLiquid
    ? (data?.amount / (data?.pastMonthLiquid || 1) - 1) * 100
    : 0;

  return (
    <Box>
      <CreateCharge />

      {showFilters ? (
        //
        <SimpleGrid
          mb={3}
          columns={{ base: 2, md: 3, xl: 4 }}
          spacing={{ base: 3, md: 3, xl: 4 }}
          alignItems="center"
        >
          <SelectForm
            control={control}
            name="type"
            placeholder="Forma de Pagamento"
            label="Tipo da venda:"
            options={[
              { value: "CREDIT_CARD", label: "Crédito" },
              { value: "DEBIT_CARD", label: "Débito" },
              { value: "PIX", label: "Pix" },
              { value: "TICKET", label: "Boleto" },
              { value: "CASH", label: "Dinheiro" },
            ]}
          />
          <InputForm
            control={control}
            name="initialDate"
            label="Data inicial:"
            rules={{ required: true }}
            type="datetime-local"
            placeholder="Data inicial"
          />
          <InputForm
            control={control}
            name="finalDate"
            label="Data inicial:"
            rules={{ required: true }}
            type="datetime-local"
            placeholder="Data inicial"
          />

          {
            <FormRemoteSelectInput
              control={control}
              name="maquineta"
              label="Terminal"
              size="sm"
              onChange={(e) => {
                setFilter((prev) => ({ ...prev, maquinetaId: e.value }));
              }}
              loadDataFn={({ value, cb }) =>
                findAllTerminals({
                  filter: value,
                  limit: 10,
                  page: 0,
                  orderBy: [
                    {
                      id: "name",
                      desc: false,
                    },
                  ],
                }).then((retorno) => {
                  if (retorno?.registers?.length > 0) {
                    cb([
                      { label: "Escolher uma opção...", value: "" },
                      ...retorno.registers?.map((d: any) => ({
                        label: `${d.number} - ${d.name}`,
                        value: d.id,
                      })),
                    ]);
                  } else {
                    cb([]);
                  }
                })
              }
            />
          }
          <CustomButton
            variant="solid"
            colorScheme="brand"
            onClick={handleSubmit(buscar)}
          >
            Buscar
          </CustomButton>
          <CustomButton variant="ghost" onClick={toggleFilters}>
            Fechar filtros
          </CustomButton>
        </SimpleGrid>
      ) : (
        <SimpleGrid
          mb={3}
          columns={{ base: 1, md: 3, xl: 4 }}
          spacing={{ base: 3, md: 3, xl: 3 }}
          alignItems="center"
        >
          <CustomButton
            colorScheme="brand"
            onClick={toggleFilters}
            leftIcon={<FiFilter />}
          >
            Filtrar
          </CustomButton>
        </SimpleGrid>
      )}
      <Text fontSize="md" fontWeight="700" mb="5px" ml={5}>
        Resumo de Cobranças
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 4, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdCheck} color="green.600" />}
            />
          }
          name="Finalizadas"
          value={maskCurrency(data?.charges?.totalPaids)}
          growth={data?.charges?.paids}
        />
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdPendingActions}
                  color="yellow.400"
                />
              }
            />
          }
          name="Pendentes"
          value={maskCurrency(data?.charges?.totalPending)}
          growth={data?.charges?.pending}
        />
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdCancel} color={"red.400"} />}
            />
          }
          name="Canceladas"
          value={maskCurrency(data?.charges?.totalCanceled)}
          growth={data?.charges?.canceleds}
        />

        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineAutorenew}
                  color={brandColor}
                />
              }
            />
          }
          name="Devoluções"
          value={maskCurrency(data?.charges?.refunds)}
        />
      </SimpleGrid>

      {/* Container para os grids */}
      <Flex direction="column" flex="4" gap="20px">
        <Box>
          <Text fontSize="md" fontWeight="700" mb="5px" ml={5}>
            Resumo de Vendas
          </Text>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 4 }}
            gap="20px"
            mb="20px"
            width="100%"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Valor em Vendas"
              value={maskCurrency(data?.amount)}
              growth={percenteProfit}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={MdBlock} color={brandColor} />
                  }
                />
              }
              name="Valor bloqueado"
              value={maskCurrency(userData?.amountLocked || 0)}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdAttachMoney}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Líquido Clientes"
              value={maskCurrency(data?.liquidCustumer)}
            />
          </SimpleGrid>
          <SimpleGrid //ADICIONAR GRÁFICO
            columns={{ base: 1, md: 2, lg: 4 }}
            gap="20px"
            mb="20px"
            width="100%"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="brand.900"
                  icon={<Icon w="32px" h="32px" as={MdSell} color="white" />}
                />
              }
              name="Total de vendas"
              value={data?.total}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="brand.900"
                  icon={<Icon w="28px" h="28px" as={MdTaskAlt} color="white" />}
                />
              }
              name="Finalizadas"
              value={data?.fineshed}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="brand.900"
                  icon={<Icon w="32px" h="32px" as={MdCancel} color="white" />}
                />
              }
              name="Canceladas"
              value={data?.canceled}
            />
          </SimpleGrid>
        </Box>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* {<TotalSpent />} */}
        <Card alignItems="center" flexDirection="column" w="100%">
          <Flex align="center" w="100%" py="10px">
            <VStack>
              <Text
                me="auto"
                px="15px"
                color={"gray.800"}
                fontSize="xl"
                fontWeight="700"
                lineHeight="100%"
              >
                Vendas no mês
              </Text>
              <SimpleGrid
                columns={{ base: 1, md: 2, xl: 2 }}
                gap="20px"
                mb="20px"
                mt={2}
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={BsCreditCardFill}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Cartão de Crédito"
                  sumValue={data?.totalCreditCard}
                  value={data?.creditCard}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      mt
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={BsCreditCard}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Cartão de Débito"
                  value={data?.debitCard}
                  sumValue={data?.totalDebitCard}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={BsCash}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Dinheiro"
                  value={data?.cash}
                  sumValue={data?.totalCash}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon w="32px" h="32px" as={MdPix} color={brandColor} />
                      }
                    />
                  }
                  name="Pix"
                  value={data?.pix}
                  sumValue={data?.totalPix}
                />{" "}
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={FaBarcode}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Boleto"
                  value={data?.ticket}
                  sumValue={data?.totalTicket}
                />
              </SimpleGrid>
            </VStack>
          </Flex>
        </Card>
        <WeeklyRevenue data={data?.daily} />
      </SimpleGrid>
      {/* {<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid>} */}
    </Box>
  );
}
