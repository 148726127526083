// Chakra imports
import {
  Badge,
  Box,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { Column } from "react-table";
import TableComponent from "components/TableComponent";
import SeeWithdrawel from "./components/SeeWithdrawel";
import { deleteWithdrawal, findAllWithdrawers } from "services/api.service";
import { maskDocument } from "utils/formatters";
import useAuth from "contexts/useAuth";
import CreateWithdrawel from "./components/CreateWithdrawel";
import { queryClient } from "index";
import { FaPlus, FaTrash } from "react-icons/fa";
import EditWithdrawel from "./components/EditWithdrawel";
import TransferInternal from "./components/TransferInternal";
import AntecipateWithdrawel from "./components/AnteciparValores";
import CreatePaymentQrcode from "./components/PaymentQRcode";
import CustomButton from "components/CustomButton";

export interface CustomerAccounts {
  account: string;
  agency: string;
  bankIspb: string;
  accountHolderDocument: string;
  accountHolder: string;
  pix: string;
  id: string;
}

export interface Customer {
  id: string;
  name: string;
  document_number: string;
}

export interface Withdrawal {
  amount: string;
  createdAt: Date;
  fee: string;
  description: string;
  status: "PENDING" | "IN_PAYMENT" | "FINISHED" | "CANCELLED";
  customerId: string;
  billetCode: string;
  type: "BILLET" | "DICT" | "QRCODE";
  qrCode: string;
  id: string;
  imageUrl?: string;
  dueDate?: string;
  CustomerAccounts?: CustomerAccounts;
  Customer: Customer;
  WithdrawelSulcredi: WithdrawelSulcredi[];
  withdrawSulcredi: WithdrawSulcredi;
}

export interface WithdrawSulcredi {
  endToEndId: string;
  status: number;
  updatedAt: string;
  pixKey: string;
  type: string;
  debtorAccount: DebtorAccount2;
  creditorAccount: CreditorAccount2;
}

export interface DebtorAccount2 {
  ispb: string;
  ispbName: string;
  name: string;
  document: string;
}

export interface CreditorAccount2 {
  ispb: string;
  ispbName: string;
  document: string;
  name: string;
}
export interface WithdrawelSulcredi {
  id: string;
  withdrawelId: string;
  status: number;
  endToEndId: string;
  errorCode: any;
  message: any;
  payeeName: any;
  payeeDocument: any;
  amount: string;
  integrationId: string;
  payload: PayloadSulcredi;
  createdAt: string;
  updatedAt: string;
}

export interface PayloadSulcredi {
  id: number;
  txId: any;
  pixKey: string;
  status: string;
  payment: Payment;
  refunds: any[];
  createdAt: string;
  errorCode: any;
  endToEndId: string;
  webhookType: string;
  debtorAccount: DebtorAccount;
  idempotencyKey: string;
  creditDebitType: string;
  creditorAccount: CreditorAccount;
  localInstrument: string;
  transactionType: string;
  remittanceInformation: any;
}

export interface Payment {
  amount: string;
  currency: string;
}

export interface DebtorAccount {
  ispb: string;
  name: string;
  issuer: string;
  number: string;
  document: string;
  accountType: string;
}

export interface CreditorAccount {
  ispb: string;
  name: string;
  issuer: string;
  number: string;
  document: string;
  accountType: string;
}

export enum StatusWithdrawal {
  PENDING = "Pendente",
  IN_PAYMENT = "Em Pagamento",
  FINISHED = "Finalizado",
  CANCELED = "Cancelado",
}

const mapType = {
  BILLET: "Boleto",
  PIX: "Pix",
  MANU: "Pix Manual",
  DICT: "Pix (DICT)",
  QRCODE: "Pix (Copia e Cola)",
};

export default function Withdrawals() {
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const { updateUserData } = useAuth();
  const toast = useToast();

  const handleCancelWithdrawal = (id: string) => {
    deleteWithdrawal(id)
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["findAllWithdrawers"],
          type: "active",
        });
        updateUserData();
        toast({
          title: "Sucesso",
          description: "Saque cancelado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((error) => {
        toast({
          title: "Erro",
          description: error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome",
          accessor: "CustomerAccounts.accountHolder",
          Cell: ({ value }: any) => {
            return value ?? "---";
          },
        },
        {
          Header: "Documento",
          accessor: "CustomerAccounts.accountHolderDocument",
          Cell: (data: any) => {
            return (
              maskDocument(data.value ?? data.row.original.receiverDocument) ||
              "---"
            );
          },
        },
        {
          Header: "Descrição",
          accessor: "description",
          Cell: ({ value }: any) => {
            return (
              <Tooltip label={value}>
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  maxW="120px"
                >
                  {value}
                </Text>
              </Tooltip>
            );
          },
        },
        {
          Header: "Tipo",
          accessor: "type",
          align: "right",
          Cell: ({ value }) => {
            return mapType[value as keyof typeof mapType];
          },
        },
        {
          Header: "Valor do Saque",
          accessor: "amount",
          align: "right",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          Header: "Taxa",
          accessor: "fee",
          Cell: ({ value, row }: any) => {
            if (row.original?.type === "PERCENTUAL") {
              return `${value}%`;
            }

            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },

        {
          Header: "Status",
          align: "right",
          accessor: "status",
          Cell: ({ value }) => {
            let colorScheme = "yellow";
            if (value === "CANCELED") {
              colorScheme = "red";
            }
            if (value === "FINISHED") {
              colorScheme = "green";
            }

            return (
              <Badge colorScheme={colorScheme}>
                {StatusWithdrawal[value as keyof typeof StatusWithdrawal]}
              </Badge>
            );
          },
        },
        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: Withdrawal) => (
    <ButtonGroup key={row.id}>
      <SeeWithdrawel data={row}></SeeWithdrawel>
      {row.type !== "QRCODE" && <EditWithdrawel data={row}></EditWithdrawel>}

      {row.status === "PENDING" && (
        <Tooltip label="Cancelar Saque">
          <IconButton
            aria-label="Cancelar Saque"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<FaTrash />}
            onClick={() => handleCancelWithdrawal(row.id)}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {isOpenCreate && (
          <CreateWithdrawel
            isOpen={isOpenCreate}
            onClose={() => {
              queryClient.refetchQueries({
                queryKey: ["findAllWithdrawers"],
                type: "active",
              });
              onCloseCreate();
            }}
          ></CreateWithdrawel>
        )}

        <TableComponent
          columnsData={columns}
          queryFn={findAllWithdrawers}
          queryKey="findAllWithdrawers"
          label="Saques"
          actions={actions}
          right={
            <>
              <CreatePaymentQrcode />
              <TransferInternal />
              <AntecipateWithdrawel />
              <CustomButton
                variant="solid"
                onClick={onOpenCreate}
                leftIcon={<FaPlus />}
                colorScheme="brand"
              >
                Solicitar Saque
              </CustomButton>
            </>
          }
        />
      </SimpleGrid>
    </Box>
  );
}
