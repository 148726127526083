export enum RecurrencyPeriod {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum RecurrencyPeriodDescription {
  DAILY = "Diário",
  WEEKLY = "Semanal",
  MONTHLY = "Mensal",
  YEARLY = "Anual",
}
