// Chakra imports
import {
  Badge,
  Box,
  Card,
  CardHeader,
  Divider,
  HStack,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

import { getRecurrence } from "services/api.service";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { DateTime } from "luxon";
import AddClientToRecurrence from "./components/AddClientToRecurrence";
import { RecurrenceClient } from "services/types/Recurrence.entity";
import { maskCurrency } from "utils/number";
import ShowPaymentsRecurrence from "./components/ShowPayments";
import LinkWalletToRecurrency from "./components/LinkWalletToRecurrency";
import RealizarLevantamento from "./components/RealizarLevantamento";

const ShowClients: React.FC<{
  clients?: RecurrenceClient[];
  onClose: () => void;
  recurency: {
    id: string;
    name: string;
    amount: string;
  };
}> = ({ clients, onClose, recurency }) => {
  return (
    <TableContainer>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Cliente</Th>
            <Th isNumeric>Valor Pago</Th>
            <Th isNumeric>Valor Pendente</Th>
            <Th>Data de Cadastro</Th>
            <Th>Data fim</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clients?.map((item) => (
            <Tr key={item.id}>
              <Td>{item.client.name}</Td>
              <Td isNumeric> {maskCurrency(item.totalPaid)}</Td>
              <Td isNumeric>
                <Badge
                  colorScheme={
                    +item.totalPending > 0
                      ? "red"
                      : +item.totalPending === 0
                      ? "green"
                      : "orange"
                  }
                >
                  {maskCurrency(item.totalPending)}
                </Badge>
              </Td>
              <Td>
                {item?.createdAt
                  ? DateTime.fromISO(item?.createdAt).toFormat(
                      "dd/MM/yyyy HH:mm"
                    )
                  : "---"}
              </Td>
              <Td>
                {item?.endDate
                  ? DateTime.fromISO(item?.endDate).toFormat(
                      "dd/MM/yyyy HH:mm"
                    )
                  : "---"}
              </Td>
              <Td>
                <ShowPaymentsRecurrence
                  clientRecurrencyId={item.id}
                  recurrencyName={recurency.name}
                  amount={recurency.amount}
                  oncloseMainModal={onClose}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default function ShowRecurrence() {
  const { id } = useParams<{ id: string }>();

  const { data, refetch } = useQuery(["getRecurrence", id], () =>
    getRecurrence(id)
  );
  const itens = useMemo(
    () => [
      {
        title: "Tipo operação",
        content: "Recorrência",
      },
      {
        title: "Valor",
        content: maskCurrency(data?.amount),
      },
      {
        title: "Saldo",
        content: maskCurrency(data?.balance),
      },
      {
        title: "Status",
        content: data?.status ? (
          <Badge colorScheme="green">Ativo</Badge>
        ) : (
          <Badge colorScheme="red">Inativo</Badge>
        ),
      },
      {
        title: "Data de criação",
        content: DateTime.fromISO(data?.createdAt).toFormat(
          "dd/MM/yyyy HH:mm:ss"
        ),
      },
      {
        title: "Vincular carteira",
        content: (
          <>
            {data?.wallet.length > 0 ? (
              <Badge colorScheme="green">Vinculada</Badge>
            ) : (
              <HStack>
                <Badge colorScheme="red">Não vinculada</Badge>
                <LinkWalletToRecurrency
                  recurrencyId={id}
                  oncloseMainModal={refetch}
                />
              </HStack>
            )}
          </>
        ),
      },
    ],
    [data, id, refetch]
  );

  return (
    <Box>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card px={3}>
          <CardHeader>
            <HStack justifyContent="space-between">
              <VStack>
                <Box fontSize="2xl" fontWeight="semibold">
                  Detalhes da recorrência
                </Box>
                <small>Recorrência: #{data?.id}</small>
              </VStack>
              <RealizarLevantamento
                recurrencyId={id}
                oncloseMainModal={refetch}
              />
            </HStack>
          </CardHeader>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 3, lg: 5, xl: 5 }}
            spacing={5}
          >
            {itens.map((item) => (
              <Box key={item.title} boxShadow="none" p="2" px={2}>
                <Box fontSize="md" fontWeight="semibold" color="gray.600">
                  {item.title}
                </Box>
                <Box fontSize="md">{item.content}</Box>
              </Box>
            ))}
          </SimpleGrid>
          <Divider color="gray.300" />

          <Box p="3" px={5}>
            <Box fontSize="md" mb={4} fontWeight="semibold" color="gray.600">
              Pagadores
            </Box>
            <AddClientToRecurrence recurrenceId={id} />
            <ShowClients
              clients={data?.clients}
              onClose={refetch}
              recurency={{
                id: id,
                name: data?.description,
                amount: data?.amount,
              }}
            />
          </Box>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
