import { FormControl, FormErrorMessage, Select } from "@chakra-ui/react";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import FormLabelCustom from "./FormLabel";

const SelectForm: React.FC<{
  control: Control<any>;
  label: string;
  labelProps?: any;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions<any>;
  options: { label: string; value: string }[];
  onChange?: (value: string) => void;
}> = ({
  control,
  label,
  rules,
  name,
  placeholder,
  options,
  labelProps = {},
  onChange,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange: onChangeFunc, name, value },
        fieldState: { invalid, error },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom label={label} name={name} props={labelProps} />
          <Select
            placeholder={placeholder}
            bg="white"
            size="sm"
            onChange={(val) => {
              onChangeFunc(val);
              if (onChange) {
                onChange(val.target.value);
              }
            }}
            value={value}
            name={name}
          >
            {options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default SelectForm;
