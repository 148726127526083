import { maskCNPJ, validateCNPJ } from './cnpj';
import { maskCPF, hideSensInformation, validateCPF } from './cpf';
import { onlyNumbers } from './string';

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 14;

export function validateDocument(document?: string): boolean {
  if (!document) return false;

  const digits = onlyNumbers(document);

  if (digits.length === CPF_LENGTH) {
    return validateCPF(digits);
  }
  if (digits.length === CNPJ_LENGTH) {
    return validateCNPJ(digits);
  }
  return false;
}

export function maskDocument(
  document?: string,
  options: { hideSensInformation: boolean } = { hideSensInformation: false }
): string {
  if (!document) return ``;

  const digits = onlyNumbers(document);

  if (digits.length <= CPF_LENGTH) {
    let maskedCPF = maskCPF(digits);

    if (options.hideSensInformation) {
      maskedCPF = hideSensInformation(maskedCPF);
    }

    return maskedCPF;
  }

  return maskCNPJ(digits);
}
